import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VAppBar, {
    staticClass: "elevation-0",
    attrs: {
      "app": "",
      "color": "white",
      "height": "75"
    }
  }, [_vm.loggedIn && _vm.miniNavbar ? _c(VAppBarNavIcon, {
    on: {
      "click": _vm.openDrawer
    }
  }) : _vm._e(), _c(VSpacer), _c('div', {
    staticClass: "mt-8 col-md-5 col-lg-3"
  }, [_vm.searchBarVisible ? _c('SearchComponent') : _vm._e()], 1), _vm.loggedIn ? _c('NotificationComponent') : _vm._e(), _vm.loggedIn ? _c(VMenu, {
    attrs: {
      "offset-y": "",
      "min-height": "800px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c('div', _vm._g(_vm._b({
          staticClass: "primary rounded-pill fluid",
          staticStyle: {
            "display": "grid",
            "place-items": "center",
            "grid-template-columns": "2fr 0fr"
          }
        }, 'div', attrs, false), on), [!_vm.isMobile() ? _c('span', {
          staticClass: "text-h6 font-weight-bold text-center white--text ml-2 px-4 py-3"
        }, [_vm._v(" " + _vm._s(_vm.user.username) + " ")]) : _vm._e(), _c(VAvatar, {
          staticClass: "elevation-0",
          attrs: {
            "size": "54"
          }
        }, [_c('img', {
          attrs: {
            "src": _vm.user_img_base64,
            "alt": "Profilbild"
          }
        })])], 1)];
      }
    }], null, false, 3323372221)
  }, [_c('div', {
    staticClass: "white px-4",
    staticStyle: {
      "height": "330px"
    }
  }, [_c(VCardTitle, {
    staticClass: "headline justify-center pt-8"
  }, [_c(VAvatar, {
    attrs: {
      "size": "100px"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.user_img_base64,
      "alt": "Profilbild"
    }
  })])], 1), _c(VCardSubtitle, {
    staticClass: "mt-4 text-h5 font-weight-bold text-center"
  }, [_vm._v(" " + _vm._s(_vm.user.username) + " ")]), _c(VCardText, {
    staticClass: "text-center text-h6 grey--text text--darken-3"
  }, [_vm._v(_vm._s(_vm.user.email) + " ")]), _c(VCardActions, {
    staticClass: "justify-center mb-4"
  }, [_c(VBtn, {
    staticClass: "rounded-pill px-8",
    attrs: {
      "color": "primary darken-1",
      "text": "",
      "outlined": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Abmelden ")])], 1)], 1)]) : _vm._e(), _vm.loginButtonVisible ? _c(VBtn, {
    attrs: {
      "elevation": "2",
      "large": "",
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v("Login")]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };