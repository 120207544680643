export default {
  props: {
    failMessage: {
      type: String,
      required: true
    },
    opened: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    }
  }
};