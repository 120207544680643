export class Person {
  static defaultValues = {
    isConsumer: false,
    isAdultRepresentation: false,
    isPatient: false,
    isRelative: false,
    isInterested: false,
    isEmployee: false,
    isDoctor: false,

    salutation: '',
    title: '',
    firstname: '',
    lastname: '',
    gender: null,
    dateOfBirth: '',
    address: {
      zip: null,
      city: '',
      street: '',
      country: null
    },
    tel: '',
    telNote: '',
    mobile: null,
    mobileNote: null,
    email: '',
    customerGroup: null,
    residence: null,
    adultRepresentation: null,

    patient: {
      healthInsuranceCompany: null,
      insuredGroup: null,
      isFeeReady: false,
      diseases: {
        diabetes: false,
        angiopathe: false,
        neuropathie: false,
        allergies: ''
      },
      regojahr: '',
      svnr: '',
      svnrDat: '',
      familyDoctor: {
        key: null,
        hvnr: ''
      },
      mainInsuranceData: {
        lastname: '',
        firstname: '',
        gender: null,
        svnr: '',
        svnrDat: ''
      }
    },
    employee: {
      healthInsuranceCompany: null,
      svnr: '',
      svnrDat: '',
      staffId: '',
      idNumber: '',
      entryDate: null,
      exitDate: null,
      userId: '',
      warehouse: null,
      isCashier: false,
      isBdeEmployee: false,
      isExternalEmployee: false
    },
    doctor: {
      hvnr: '',
      subject: {}
    }
  }

  static testValues = {
    isConsumer: true,
    isAdultRepresentation: false,
    isPatient: true,
    isRelative: true,
    isEmployee: false,
    isDoctor: false,
    salutation: 'Herr',
    title: '',
    firstname: 'Test',
    lastname: 'Joe',
    gender: { lfdnr: 2 },
    dateOfBirth: '2023-07-09',
    address: {
      zip: 3300,
      city: 'Amstetten',
      street: 'Hauptplatz 1',
      country: { firma: 1, sprache: 1, lfdnr: 58 }
    },
    tel: '06601234567',
    telNote: '',
    mobile: null,
    mobileNote: null,
    email: '',
    customerGroup: null,
    residence: null,
    adultRepresentation: null,
    patient: {
      healthInsuranceCompany: {
        name: 'Allgemeine Unfallversicherungsanstalt',
        key: { firma: 1, lfdnr: 1001 },
        street: 'Eisengasse 12',
        city: 'Dornbirn',
        zip: 6850,
        description: 'Landesstelle Vorarlberg',
        shortDescription: 'AUVA-V',
        displayName:
          'AUVA-V - Allgemeine Unfallversicherungsanstalt (Landesstelle Vorarlberg) 6850 Dornbirn Eisengasse 12'
      },
      insuredGroup: { firma: 1, lfdnr: 2 },
      isFeeReady: false,
      diseases: {
        diabetes: false,
        angiopathe: false,
        neuropathie: false,
        allergies: ''
      },
      regojahr: '',
      svnr: '5605',
      svnrDat: '090723',
      familyDoctor: { firma: 1, lfdnr: 48000582 },
      mainInsuranceData: {
        lastname: 'Hauptversicherter',
        firstname: 'Hugo',
        gender: { lfdnr: 3 },
        svnr: '5053',
        svnrDat: '240107',
        healthInsuranceCompany: {
          name: 'Allgemeine Unfallversicherungsanstalt',
          key: { firma: 1, lfdnr: 1003 },
          street: 'Webergasse 4',
          city: 'Wien',
          zip: 1203,
          description: 'Landesstelle Wien',
          shortDescription: 'AUVA-W',
          displayName:
            'AUVA-W - Allgemeine Unfallversicherungsanstalt (Landesstelle Wien) 1203 Wien Webergasse 4'
        }
      }
    },
    employee: {
      healthInsuranceCompany: {
        firma: 1,
        lfdnr: 1015
      },
      svnr: '1234',
      svnrDat: '170802',
      staffId: '12345',
      idNumber: 12345567,
      entryDate: '2023-07-04',
      exitDate: null,
      userId: 'FabZ',
      warehouse: null,
      isCashier: true,
      isBdeEmployee: true,
      isExternalEmployee: false
    },
    doctor: {
      hvnr: '123456',
      subject: {}
    }
  }

  constructor() {
    Object.assign(this, JSON.parse(JSON.stringify(Person.defaultValues)))
    Person.duplicateFound = false
  }
}

export class NonPerson {
  static duplicateFound = false
  static defaultValues = {
    isCompany: false,
    isAdultRepresentation: false,
    isResidence: false,
    isSupplier: false,
    isHealthInsuranceCompany: false,
    salutation: '',
    name: '',
    address: {
      zip: '',
      city: '',
      street: '',
      country: null
    },
    tel: '',
    telNote: '',
    mobile: null,
    mobileNote: null,
    email: '',
    customerGroup: null,
    residence: null,
    adultRepresentation: null,
    company: {
      contractNumber: '',
      uid: '',
      association: {
        firma: 1,
        lfdnr: ''
      },
      industrialSectors: {
        firma: 1,
        lfdnr: ''
      },
      deliveryAndPayment: {
        shippingMethod: {
          firma: 1,
          lfdnr: ''
        },
        shippingCondition: {
          firma: 1,
          lfdnr: ''
        },
        paymentCondition: {
          firma: 1,
          lfdnr: ''
        },
        salesPerson: {
          firma: 1,
          lfdnr: ''
        },
        collectiveDeliveryNoteInterval: '',
        collectiveInvoiceInterval: '',
        discountType: {
          firma: 1,
          lfdnr: ''
        },
        discount: '',
        warehouse: {
          firma: 1,
          code: ''
        },
        associationIsInvoiceAddress: false,
        fibuGroup: {
          firma: 1,
          code: ''
        }
      }
    },
    supplier: {
      kdNr: '',
      uid: '',
      supplierGroup: {
        firma: 1,
        code: ''
      },
      association: null,
      industrialSector: {
        firma: 1,
        lfdnr: null
      },
      fibuGroup: {
        firma: 1,
        code: ''
      },
      deliveryAndOrder: {
        shippingMethod: {
          firma: 1,
          lfdnr: null
        },
        shippingCondition: {
          firma: 1,
          lfdnr: null
        },
        priceOnDemand: false,
        priceOnDelivery: false,
        collectiveDeliveryNoteInterval: 0,
        collectiveInvoiceInterval: 0,
        directBranchShipping: false,
        directCustomerDelivery: false,
        associationVat: false,
        creditorNumber: 0,
        openedPacks: '',
        decimalPlaces: 0,
        minimumOrderValue: 0
      },
      payment: {
        paymentConditions: {
          firma: 1,
          lfdnr: null
        },
        paymentTypes: {
          firma: 1,
          lfdnr: null
        },
        iban: '',
        bic: '',
        lockPaymentProposal: false,
        directDebitAuthorisationDate: '',
        discountType: {
          firma: 1,
          lfdnr: null
        },
        discount: ''
      }
    },
    healthInsuranceCompany: {
      association: null,
      wageGroup: null,
      fibuGroup: null,
      kdNr: '',
      contractPartnerNumber: '',
      uid: '',
      accounting: {
        serviceInsuranceCarrier: null,
        serviceState: null,
        dataInsuranceCarrier: null,
        accountingInsuranceCarrier: null,
        accountingState: null,
        externalInvoiceNumber: false,
        excessInvoiceSeparation: false
      },
      documentControl: {
        collectiveInvoiceInterval: 0,
        collectiveInvoiceType: 0,
        collectiveInvoiceCoverSheet: 0,
        ownContributionGroup: null,
        dataCarrier: false,
        grossPrices: false,
        groupInvoice: false,
        excess: false,
        autoFreeAdmission: false,
        checkState: false,
        lumSumExcessCode: false
      }
    }
  }

  static testValues = {
    isCompany: true,
    isAdultRepresentation: false,
    isResidence: false,
    isSupplier: false,
    isHealthInsuranceCompany: false,
    salutation: 'FA',
    name: 'Lebenshilfe NÖ - TBW, WVB und WA Purgstall',
    address: {
      zip: 3251,
      city: 'Purgstall',
      street: 'Hochrießerstraße 12',
      country: {
        firma: 1,
        sprache: 1,
        lfdnr: 1
      }
    },
    tel: null,
    telNote: null,
    mobile: '+43 676 6691183',
    mobileNote: 'Leitung',
    email: 'wvb.purgstall@noe.lebenshilfe.at',
    customerGroup: {
      firma: 1,
      code: 'HEIM'
    },
    residence: null,
    adultRepresentation: null,
    company: {
      contractNumber: '',
      uid: '',
      association: {
        firma: 1,
        lfdnr: ''
      },
      industrialSectors: {
        firma: 1,
        lfdnr: ''
      },
      deliveryAndPayment: {
        shippingMethod: {
          firma: 1,
          lfdnr: ''
        },
        shippingCondition: {
          firma: 1,
          lfdnr: ''
        },
        paymentCondition: {
          firma: 1,
          lfdnr: ''
        },
        salesPerson: {
          firma: 1,
          lfdnr: ''
        },
        collectiveDeliveryNoteInterval: '',
        collectiveInvoiceInterval: '',
        discountType: {
          firma: 1,
          lfdnr: ''
        },
        discount: '',
        warehouse: {
          firma: 1,
          code: ''
        },
        associationIsInvoiceAddress: false,
        fibuGroup: {
          firma: 1,
          code: ''
        }
      }
    },
    supplier: {
      kdNr: '1234',
      uid: 'AT-12324522',
      supplierGroup: {
        firma: 1,
        code: 'ZZZZZZZZ'
      },
      association: null,
      industrialSector: {
        firma: 1,
        lfdnr: 13
      },
      fibuGroup: {
        firma: 1,
        code: '0'
      },
      deliveryAndOrder: {
        shippingMethod: {
          firma: 1,
          lfdnr: 1
        },
        shippingCondition: {
          firma: 1,
          lfdnr: 2
        },
        priceOnDemand: true,
        priceOnOrder: false,
        collectiveDeliveryNoteInterval: 4,
        collectiveInvoiceInterval: 14,
        directBranchShipping: false,
        directCustomerDelivery: true,
        associationVat: false,
        creditorNumber: 2,
        openedPacks: '+',
        decimalPlaces: 2,
        minimumOrderValue: 40
      },
      payment: {
        paymentConditions: {
          firma: 1,
          lfdnr: 2
        },
        paymentTypes: {
          firma: 1,
          lfdnr: 254
        },
        iban: 'AT56 68779 7980 789 89',
        bic: 'kladjlf',
        lockPaymentProposal: false,
        directDebitAuthorisationDate: '2023-08-17',
        discountType: {
          firma: 1,
          lfdnr: 3
        },
        discount: '25'
      }
    },
    healthInsuranceCompany: {
      association: null,
      wageGroup: {
        firma: 1,
        code: 'K-OST'
      },
      fibuGroup: {
        firma: 1,
        code: '1'
      },
      kdNr: '69543',
      contractPartnerNumber: '123456',
      uid: 'AT-15342234',
      accounting: {
        serviceInsuranceCarrier: {
          firma: 1,
          land: 1,
          code: '08'
        },
        serviceState: {
          firma: 1,
          lfdnr: 1,
          bundesland: 2
        },
        dataInsuranceCarrier: {
          firma: 1,
          land: 1,
          code: '08'
        },
        accountingInsuranceCarrier: {
          firma: 1,
          land: 1,
          code: '08'
        },
        accountingState: {
          firma: 1,
          lfdnr: 1,
          bundesland: 2
        },
        externalInvoiceNumber: true,
        excessInvoiceSeparation: false
      },
      documentControl: {
        collectiveInvoiceInterval: 10,
        collectiveInvoiceType: 6,
        collectiveInvoiceCoverSheet: 277,
        ownContributionGroup: {
          firma: 1,
          lfdnr: 1002
        },
        dataCarrier: true,
        grossPrices: false,
        groupInvoice: false,
        excess: true,
        autoFreeAdmission: false,
        checkState: true,
        lumSumExcessCode: false
      }
    }
  }
  constructor() {
    Object.assign(this, JSON.parse(JSON.stringify(NonPerson.defaultValues)))

    NonPerson.duplicateFound = false
  }
}
