import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c(VCard, {
    staticClass: "rounded-xl",
    attrs: {
      "elevation": "0"
    }
  }, [_c(VCardTitle, {
    staticClass: "justify-center mx-auto ma-4",
    staticStyle: {
      "max-width": "450px"
    }
  }, [_c(VImg, {
    staticClass: "mt-8",
    attrs: {
      "src": require("@/assets/svg/undraw_page_not_found_re_e9o6.svg"),
      "max-width": "350px"
    }
  })], 1), _c(VCardSubtitle, {
    staticClass: "text-h4 text-center black--text mt-4 pb-4"
  }, [_c('h3', {
    staticClass: "my-4"
  }, [_vm._v("Fehler 404")]), _c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Seite nicht gefunden.")])]), _c(VCardActions, {
    staticClass: "d-flex justify-center pb-4"
  }, [_c(VBtn, {
    staticClass: "px-8 py-4",
    attrs: {
      "color": "white",
      "rounded": "",
      "elevation": "1"
    },
    on: {
      "click": _vm.routeToHome
    }
  }, [_vm._v(" Zurück auf die Homepage ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };