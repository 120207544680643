//import axios from 'axios'

export default {
  getAddressPicturesArray(addressPictures) {
    var imagesTab1 = []
    var imagesTab2 = []
    var imagesTab3 = []
    var imagesTab4 = []
    var imagesTab5 = []

    addressPictures.forEach(image => {
      // Swapped 2 and 3 like in REV...
      if (image.imageType == 0) {
        imagesTab1.push(image)
      } else if (image.imageType == 2) {
        imagesTab3.push(image)
      } else if (image.imageType == 3) {
        imagesTab2.push(image)
      } else if (image.imageType == 4) {
        imagesTab4.push(image)
      } else if (image.imageType == 5) {
        imagesTab5.push(image)
      }
    })

    return [imagesTab1, imagesTab2, imagesTab3, imagesTab4, imagesTab5]
  }
}
