import axios from 'axios'

export default {
  state: {
    BillAddressItems: [],
    BillAddressItemsFiltered: [],
    ShippingAddressItems: [],
    ShippingAddressItemsFiltered: [],
    Details: {}
  },

  mutations: {
    SET_Addresses(state, payload) {
      state.BillAddressItems = payload
      state.BillAddressItemsFiltered = []
      payload.forEach(element => {
        state.BillAddressItemsFiltered.push(element.address)
      })
      state.ShippingAddressItems = payload
      state.ShippingAddressItemsFiltered = []
      payload.forEach(element => {
        state.ShippingAddressItemsFiltered.push(element.address)
      })
    },
    SET_DETAILS(state, payload) {
      state.Details = payload
    }
  },
  actions: {
    GET_Offer_Address_Addresses({ commit }, payload) {
      axios.get(`v1/offer/${payload}/customer/addresses`).then(response => {
        commit('SET_Addresses', response.data)
      })
    }
  }
}
