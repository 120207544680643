import axios from 'axios'

export default {
  state: {
    availableApps: [],
    shaking: false
  },
  mutations: {
    SET_AVAILABLE_APPS(state, availableApps) {
      state.availableApps = availableApps
    }
  },
  actions: {
    loadAppsAvailableForCurrentUser({ commit }) {
      return axios.get('/v1/apps').then(data => {
        // Filter out apps that have false set in show_in_menu
        const filteredApps = data.data

        commit('SET_AVAILABLE_APPS', filteredApps)
      })
    },
    ensureAvailableAppsLoaded({ state, dispatch }) {
      if (state.availableApps == null || state.availableApps.length == 0) {
        dispatch('loadAppsAvailableForCurrentUser')
      }
    }
  },
  getters: {
    availableApps(state) {
      return state.availableApps
    },
    lbaApps(state) {
      return state.availableApps
        .filter(item => item.showInMenu == true)
        .filter(item => item.appCategory != 'LBA-SETTINGS')
    },
    lbaSettingsApps(state) {
      return state.availableApps.filter(
        item => item.appCategory == 'LBA-SETTINGS'
      )
    },
    availableAppPermissions(state) {
      return state.availableApps.filter(item => item.hasPermission == false)
    },
    userHasApp: state => appName => {
      return (
        state.availableApps.filter(item => item.appBezeichnung === appName)
          .length != 0
      )
    }
  }
}
