export default {
  formatNumber(value, digits) {
    const formattedNumber = Number(value).toFixed(digits)
    return this.formatCurrency(formattedNumber, digits)
  },
  formatCurrency(value, digits) {
    return Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: digits
    })
      .format(value)
      .replace('€', '')
  },
  formatDate(value) {
    return new Date(value).toLocaleDateString('uk-Uk')
  }
}
