import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VChip, {
    staticClass: "primary--text",
    attrs: {
      "small": _vm.small,
      "color": _vm.color,
      "outlined": ""
    },
    on: {
      "click": _vm.goToChangelog
    }
  }, [_c(VIcon, {
    attrs: {
      "small": _vm.small,
      "left": ""
    }
  }, [_vm._v("mdi-source-branch")]), _vm._v(" " + _vm._s(_vm.VERSION) + " ")], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };