import axios from 'axios'

export default {
  getCustomerGroupsForAddress(lfdnr) {
    return axios
      .get(`/v1/crm/addresses/${lfdnr}/customerGroups`)
      .then(response => response.data)
  },

  createCustomerGroupForAddress(lfdnr, addressTariff) {
    return axios
      .post(`/v1/crm/addresses/${lfdnr}/customerGroups`, addressTariff)
      .then(response => response)
  },

  updateCustomerGroupForAddress(lfdnr, oldCode, toUpdate) {
    return axios
      .put(`/v1/crm/addresses/${lfdnr}/customerGroups/${oldCode}`, toUpdate)
      .then(response => response)
  },

  deleteCustomerGroupForAddress(lfdnr, customerGroupCode) {
    return axios
      .delete(`/v1/crm/addresses/${lfdnr}/customerGroups/${customerGroupCode}`)
      .then(response => response.data)
  }
}
