export default {
  data: () => ({
    addresses: [],
    AddressSearch: false,
    header: [{
      text: 'Name',
      value: 'name',
      align: 'start'
    }, {
      text: 'SVNR',
      value: 'svnr',
      align: 'left'
    }, {
      text: 'Straße',
      value: 'street',
      align: 'left'
    }, {
      text: 'PLZ',
      value: 'plz',
      align: 'left'
    }, {
      text: 'Hinzufügen',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '12rem'
    }]
  }),
  methods: {
    open(addresses) {
      this.addresses = addresses;
      this.AddressSearch = true;
    },
    addAddress(item) {
      this.$emit('addAddress', item);
      this.addresses.splice(this.addresses.indexOf(item), 1);
    }
  }
};