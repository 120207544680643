import calendarService from '@/services/cal/calendarService.js';
import { mapState } from 'vuex';
import SerialAppointmentSummary from '@/components/calendar_v2/SerialAppointmentSummary.vue';
export default {
  data: () => ({
    serialAppointmentDialog: false,
    appointment: [],
    typesOfRepetition: ['Tage', 'Wochen', 'Monate'],
    endTypes: ['am', 'nach'],
    endType: 1,
    datepicker: false,
    serialData: {},
    weekdays: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
    today: new Date(Date.now()),
    repeatTypeMonth: ['Monatstag', 'Wochentag'],
    calendarService
  }),
  components: {
    SerialAppointmentSummary
  },
  computed: {
    ...mapState({
      serialAppointmentPreviews: state => state.calAppointments.serialAppointmentPreviews
    })
  },
  methods: {
    open(appointment) {
      this.setDefaultValues();
      this.appointment = appointment;
      this.serialAppointmentDialog = true;
    },
    close() {
      this.$emit('close');
      this.serialAppointmentDialog = false;
    },
    setDefaultValues() {
      this.endType = 2;
      this.serialData = {
        typeOfRepetition: 'Tage',
        repetitionCycle: 1,
        repetitions: 2,
        weekdays: [],
        repeatUntil: this.today.toISOString().substring(0, 10),
        dayOfMonth: 'Monatstag'
      };
    },
    calcAppointments() {
      console.warn(this.serialData);
      var converted = JSON.parse(JSON.stringify(this.serialData));
      converted.dayOfMonth = converted.dayOfMonth === 'Monatstag' ? converted.dayOfMonth = true : converted.dayOfMonth = false;
      var weekdays = this.weekdays;
      converted.weekdays = converted.weekdays.map(function (day) {
        return weekdays.indexOf(day) + 1;
      });
      switch (converted.typeOfRepetition) {
        case 'Tage':
          converted.typeOfRepetition = 'DAILY';
          break;
        case 'Monate':
          converted.typeOfRepetition = 'MONTHLY';
          break;
        case 'Wochen':
          converted.typeOfRepetition = 'WEEKLY';
          break;
      }
      switch (this.endType) {
        case 1:
          converted.repetitions = null;
          break;
        case 2:
          converted.repeatUntil = this.today;
          break;
      }
      converted.firstAppointment = this.appointment;
      console.warn(converted);
      this.$store.dispatch('loadSerialAppointmentPreviews', converted).then(() => {
        console.warn(this.serialAppointmentPreviews);
        this.$refs.summary.open(this.serialAppointmentPreviews);
      });
    }
  }
};