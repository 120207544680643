export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary'
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    VERSION() {
      return `${process.env.VUE_APP_VERSION}`;
    }
  },
  methods: {
    goToChangelog() {
      //this.$router.push({ name: 'Changelog' })
    }
  }
};