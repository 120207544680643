//import axios from 'axios'

import axios from 'axios'

export default {
  state: {
    appointmentPreviews: [],
    appointment: [],
    foundAppointments: [],
    filter: { isSet: false },
    serialAppointmentPreviews: [],
    appointmentHistory: []
  },
  mutations: {
    SET_APPOINTMENT_PREVIEWS(state, appointmentPreviews) {
      state.appointmentPreviews = appointmentPreviews
    },
    SET_APPOINTMENT_DATA(state, appointment) {
      state.appointment = appointment
    },
    SET_FOUND_APPOINTMENTS(state, foundAppointments) {
      state.foundAppointments = foundAppointments
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    SET_SERIAL_APPOINTMENT_PREVIEWS(state, serialAppointmentPreviews) {
      state.serialAppointmentPreviews = serialAppointmentPreviews
    },
    SET_APPOINTMENT_HISTORY(state, appointmentHistory) {
      state.appointmentHistory = appointmentHistory
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async loadAppointmentPreviews({ commit, state }, { start, end }) {
      return axios
        .get('v1/calv2/appointments/previews', {
          params: { start: start, end: end }
        })
        .then(data => {
          console.warn(state.filter)
          commit('SET_APPOINTMENT_PREVIEWS', data.data)
        })
    },
    async loadAppointment({ commit }, key) {
      return axios.post('v1/calv2/appointments/details', key).then(data => {
        commit('SET_APPOINTMENT_DATA', data.data)
      })
    },
    async searchAppointments({ commit }, q) {
      return axios
        .get('v1/calv2/appointments/search', {
          params: { q: q }
        })
        .then(data => {
          commit('SET_FOUND_APPOINTMENTS', data.data)
        })
    },
    async loadSerialAppointmentPreviews({ commit }, serial) {
      return axios
        .post(`v1/calv2/appointments/serial/preview`, serial)
        .then(data => {
          commit('SET_SERIAL_APPOINTMENT_PREVIEWS', data.data)
        })
    },
    async loadAppointmentHistory({ commit }, appointment) {
      return axios
        .post(`/v1/calv2/appointments/history`, appointment)
        .then(response => {
          commit('SET_APPOINTMENT_HISTORY', response)
        })
    }
  }
}
