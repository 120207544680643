import axios from 'axios'

export default {
  state: {
    deliveryTypes: [],
    deliveryConditions: [],
    billingTypes: [],
    billingConditions: [],
    billingCurrencies: []
  },
  mutations: {
    SET_DELIVERY_TYPES(state, deliveryTypes) {
      state.deliveryTypes = deliveryTypes
    },
    SET_DELIVERY_CONDITIONS(state, deliveryConditions) {
      state.deliveryConditions = deliveryConditions
    },
    SET_BILLING_TYPES(state, billingTypes) {
      state.billingTypes = billingTypes
    },
    SET_BILLING_CONDITIONS(state, billingConditions) {
      state.billingConditions = billingConditions
    },
    SET_BILLING_CURRENCIES(state, billingCurrencies) {
      state.billingCurrencies = billingCurrencies
    }
  },
  actions: {
    async getAllDeliveryTypes({ commit }) {
      await axios.get(`v1/business/document/delivery/types`).then(response => {
        commit('SET_DELIVERY_TYPES', response.data)
      })
    },
    async getAllDeliveryConditions({ commit }) {
      await axios
        .get(`v1/business/document/delivery/conditions`)
        .then(response => {
          commit('SET_DELIVERY_CONDITIONS', response.data)
        })
    },
    async getAllBillingTypes({ commit }) {
      await axios.get(`v1/business/document/billing/types`).then(response => {
        commit('SET_BILLING_TYPES', response.data)
      })
    },
    async getAllBillingConditions({ commit }) {
      await axios
        .get(`v1/business/document/billing/conditions`)
        .then(response => {
          commit('SET_BILLING_CONDITIONS', response.data)
        })
    },
    async getAllBillingCurrencies({ commit }) {
      await axios
        .get(`v1/business/document/billing/currencies`)
        .then(response => {
          commit('SET_BILLING_CURRENCIES', response.data)
        })
    }
  }
}
