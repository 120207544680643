import axios from 'axios'

export default {
  state: {
    articleTypes: [],
    articleCategories: [],
    articleGroups: [],
    statisticGroups: [],
    statisticCodes: [],
    commissionGroups: [],
    revenueGroups: [],
    objectTypes: [],
    objectTypeGroups: [],
    foundArticles: [],
    filter: {
      articleNumber: null,
      articleNumber2: null,
      articleDescription: null,
      articleType: null,
      articleCategory: null,
      articleGroup: null,
      statisticGroups: null,
      statisticCodes: null,
      commissionGroups: null,
      revenueGroups: null,
      objectTypes: null,
      objectTypeGroups: null
    }
  },

  mutations: {
    //Setter
    SET_ARTICLETYPES(state, articleTypes) {
      state.articleTypes = articleTypes
    },

    SET_ARTICLECATEGORIES(state, articleCategories) {
      state.articleCategories = articleCategories
    },

    SET_ARTICLEGROUPS(state, articleGroups) {
      state.articleGroups = articleGroups
    },

    SET_STATISTICGROUPS(state, statisticGroups) {
      state.statisticGroups = statisticGroups
    },

    SET_STATISTICCODES(state, statisticCodes) {
      state.statisticCodes = statisticCodes
    },

    SET_COMMISSIONGROUPS(state, commissionGroups) {
      state.commissionGroups = commissionGroups
    },

    SET_REVENUEGROUPS(state, revenueGroups) {
      state.revenueGroups = revenueGroups
    },

    SET_OBJECTTYPES(state, objectTypes) {
      state.objectTypes = objectTypes
    },

    SET_OBJECTTYPEGROUPS(state, objectTypeGroups) {
      state.objectTypeGroups = objectTypeGroups
    },

    SET_FOUND_ARTICLES(state, foundArticles) {
      state.foundArticles = foundArticles
    },

    SET_FILTER(state, filter) {
      state.filter.articleNumber = filter.articleNumber
      state.filter.articleNumber2 = filter.articleNumber2
      state.filter.articleDescription = filter.articleDescription
      state.filter.articleCategory = filter.articleCategory
      state.filter.articleType = filter.articleType
      state.filter.articleGroup = filter.articleGroup
      state.filter.statisticGroup = filter.statisticGroup
      state.filter.statisticCode = filter.statisticCode
      state.filter.commissionGroup = filter.commissionGroup
      state.filter.revenueGroup = filter.revenueGroup
      state.filter.objectType = filter.objectType
      state.filter.objectTypeGroup = filter.objectTypeGrou
      /*state.filter = {
        articleNumber: filter.articleNumber,
        articleNumber2: filter.articleNumber2
        //articleDescription: filter.articleDescription,
        //articleCategory: filter.articleCategory,
        //articleType: filter.articleType,
        //articleGroup: filter.articleGroup
      }*/
    }
  },

  actions: {
    //ladet Artikeltypen für Combobox
    async loadArticleTypes({ commit }) {
      axios.get(`/v1/articles/types`).then(response => {
        commit('SET_ARTICLETYPES', response.data)
      })
    },

    //ladet Artikelkategorien für Combobox
    async loadArticleCategories({ commit }) {
      axios.get(`/v1/articles/categories`).then(response => {
        commit('SET_ARTICLECATEGORIES', response.data)
      })
    },

    //ladet Artikelgruppen für Combobox
    async loadArticleGroups({ commit }) {
      axios.get(`/v1/articles/groups`).then(response => {
        commit('SET_ARTICLEGROUPS', response.data)
      })
    },

    //ladet Statistikgruppen für Combobox
    async loadStatisticGroups({ commit }) {
      axios.get(`/v1/statistics/articles/statisticGroups`).then(response => {
        commit('SET_STATISTICGROUPS', response.data)
      })
    },

    //ladet Statistikcodes für Combobox
    async loadStatisticCodes({ commit }) {
      axios.get(`/v1/statistics/articles/codes`).then(response => {
        commit('SET_STATISTICCODES', response.data)
      })
    },

    //ladet Provisionsgruppen für Combobox
    async loadCommissionGroups({ commit }) {
      axios.get(`/v1/statistics/revenue/provision`).then(response => {
        commit('SET_COMMISSIONGROUPS', response.data)
      })
    },

    //ladet Erlösgruppen für Combobox
    async loadRevenueGroups({ commit }) {
      axios.get(`/v1/statistics/revenue/groups`).then(response => {
        commit('SET_REVENUEGROUPS', response.data)
      })
    },

    //ladet Objekttypen für Combobox
    async loadObjectTypes({ commit }) {
      axios.get(`/v1/statistics/articles/objecttyp`).then(response => {
        commit('SET_OBJECTTYPES', response.data)
      })
    },

    //ladet Objekttypngruppen für Combobox
    async loadObjectTypeGroups({ commit }) {
      axios.get(`/v1/statistics/articles/objectgrouptyp`).then(response => {
        commit('SET_OBJECTTYPEGROUPS', response.data)
      })
    },
    //Ladet Artikel für Volltextsuche
    async searchArticles({ commit }, searchText) {
      axios.get(`/v1/articles/search?q=${searchText}`).then(response => {
        commit('SET_FOUND_ARTICLES', response.data)
      })
    },

    async setSaveFilter({ commit }, filter) {
      console.log('filter')
      console.log(filter)
      if (filter) {
        commit('SET_FILTER', filter)
      }
    }
  },

  getters: {
    //Getter
    getFlter(state) {
      return state.filter
    },

    articleTypes(state) {
      return state.articleTypes
    },

    articleCategories(state) {
      return state.articleCategories
    },

    articleGroups(state) {
      return state.articleGroups
    },

    statisticGroups(state) {
      return state.statisticGroups
    },

    statisticCodes(state) {
      return state.statisticCodes
    },

    commissionGroups(state) {
      return state.commissionGroups
    },

    revenueGroups(state) {
      return state.revenueGroups
    },

    objectTypes(state) {
      return state.objectTypes
    },

    objectTypeGroups(state) {
      return state.objectTypeGroups
    },

    articles(state) {
      return state.articles
    },

    foundArticles(state) {
      return state.foundArticles
    }
  }
}
