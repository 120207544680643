import axios from 'axios'

export default {
  state: {
    address: {
      description: null,
      lfdnr: null, //kdnr
      street: null,
      city: null,
      country: null,
      email: null
    },
    isUserSalesperson: false,
    Salesperson: null, //Salesperson_kdnr
    AllSalespersons: [],
    AllSalespersonsFiltered: [],
    offer_wfcid: null,
    offer_create_status: null
  },

  mutations: {
    SET_OFFER_ADDRESS(state, payload) {
      //console.log(payload)
      state.address = payload
    },
    SET_OFFER_WFCID(state, payload) {
      state.offer_wfcid = payload.data.wfCid
      state.offer_create_status = payload.status
    },
    SET_isUserSalesperson(state, payload) {
      if (payload != null && payload != '') {
        state.isUserSalesperson = true
        state.Salesperson = payload
      } else {
        state.isUserSalesperson = false
        state.Salesperson = null
      }
    },
    SET_SingleSalesperson(state, payload) {
      state.Salesperson = payload
    },
    SET_AllSalespersons(state, payload) {
      state.AllSalespersons = payload
      payload.forEach(element => {
        state.AllSalespersonsFiltered.push(element.name)
      })
    }
  },
  actions: {
    async GET_OFFER_ADDRESS({ commit, dispatch }, payload) {
      dispatch('GET_Offer_Address_Addresses', payload)
      await axios.get(`v1/crm/addresses/${payload}`).then(response => {
        commit('SET_OFFER_ADDRESS', response.data)
      })
    },
    async CREATE_OFFER({ commit }, payload) {
      await axios.post('v1/offer', payload).then(response => {
        commit('SET_OFFER_WFCID', response)
      })
    },
    async GET_isUserSalesperson({ commit, dispatch }) {
      axios.get('v1/offer/isUserSalesperson').then(response => {
        commit('SET_isUserSalesperson', response.data) ^
          console.log(response.data)
        if (response.data == null || response.data == '') {
          dispatch('GET_AllSalespersons')
        }
      })
    },
    async GET_AllSalespersons({ commit }) {
      axios.get('v1/offer/salespersons').then(response => {
        commit('SET_AllSalespersons', response.data)
      })
    }
  },
  getters: {
    GETTER_AllSalespersons(state) {
      return state.AllSalespersons
    }
  }
}
