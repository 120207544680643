import axios from 'axios'

export default {
  state: {
    availableApps: []
  },
  mutations: {
    SHOP_SET_AVAILABLE_APPS(state, availableApps) {
      state.availableApps = availableApps
    }
  },
  actions: {
    loadShopAppsAvailableForCurrentUser({ commit }) {
      return axios.get('/v1/shop/apps').then(data => {
        // Filter out apps that have false set in show_in_menu
        const filteredApps = data.data.filter(app => app.showInMenu)

        commit('SHOP_SET_AVAILABLE_APPS', filteredApps)
      })
    }
  },
  getters: {
    availableShopApps(state) {
      return state.availableApps
    },
    lbaShopInfoApps(state) {
      return state.availableApps.filter(item => item.appCategory == 'LBA-INFO')
    },
    lbaShopBdeApps(state) {
      return state.availableApps.filter(item => item.appCategory == 'LBA-BDE')
    },
    lbaShopAzeApps(state) {
      return state.availableApps.filter(item => item.appCategory == 'LBA-AZE')
    },
    shop_lbaSettingsApps(state) {
      return state.availableApps.filter(
        item => item.appCategory == 'LBA-SETTINGS'
      )
    },
    availableShopAppPermissions(state) {
      return state.availableApps.filter(item => item.hasPermission == false)
    }
  }
}
