import axios from 'axios'

export default {
  state: {
    warehouses: {}
  },
  mutations: {
    SET_WAREHOUSES(state, warehouses) {
      state.warehouses = warehouses
    }
  },
  actions: {
    loadWarehouses() {
      axios.get('/v1/statistics/warehouses')
    }
  },
  getters: {}
}
