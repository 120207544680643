export default {
  data: () => ({
    objects: [],
    ObjectList: false,
    header: [{
      text: 'Code',
      value: 'key.code',
      align: 'start'
    }, {
      text: 'Beschreibung',
      value: 'description',
      align: 'left'
    }, {
      text: 'Kommentar',
      value: 'comment',
      align: 'left'
    }, {
      text: 'Besitzer',
      value: 'owner',
      align: 'left'
    }, {
      text: 'Hinzufügen',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '12rem'
    }]
  }),
  methods: {
    open(objects) {
      this.objects = objects;
      this.ObjectList = true;
    },
    addObject(item) {
      this.$emit('addObject', item);
      this.objects.splice(this.objects.indexOf(item), 1);
    }
  }
};