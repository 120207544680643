import axios from 'axios';
export default {
  data: () => ({
    forceDialog: false,
    appointment: null,
    isSerial: false
  }),
  methods: {
    open(appointment, isSerial = false) {
      this.forceDialog = true;
      this.appointment = appointment;
      this.isSerial = isSerial;
    },
    forceUpdate() {
      if (!this.isSerial) axios.put('v1/calv2/appointments/update', this.appointment, {
        params: {
          forceUpdate: true
        }
      }).then(() => {
        this.forceDialog = false;
        this.$emit('close');
      });else axios.put('v1/calv2/appointments/serial/update', this.appointment, {
        params: {
          forceUpdate: true
        }
      }).then(() => {
        this.forceDialog = false;
        this.$emit('close');
      });
    },
    cancel() {
      this.forceDialog = false;
      this.$emit('close');
    }
  }
};