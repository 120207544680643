import axios from 'axios'

export default {
  state: {
    functions: [],
    departments: []
  },
  mutations: {
    SET_FUNCTIONS(state, functions) {
      state.functions = functions
    },
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments
    }
  },
  actions: {
    async GET_FUNCTIONS({ commit }) {
      await axios
        .get('v1/crm/settings/functions')
        .then(response => commit('SET_FUNCTIONS', response.data))
    },
    async GET_DEPARTMENTS({ commit }) {
      await axios
        .get('v1/crm/settings/departments')
        .then(response => commit('SET_DEPARTMENTS', response.data))
    }
  }
}
