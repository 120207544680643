import axios from 'axios'
//import router from '@/router'
//import Vue from 'vue'
//import formatService from '@/services/formatters/articleFormatService'

export default {
  state: {
    OtherStatisticsConf: {},
    showOtherStatistics: false,
    showDataTables: false,
    SaleChartTotalRevenue: [0, 0, 0, 0],
    SaleChartDB: [0, 0, 0, 0],
    SaleChartDetailed: []
  },
  mutations: {
    SET_ConfigStatistics(state, payload) {
      state.OtherStatisticsConf = payload
    },
    SET_ShowOtherStatistics(state, payload) {
      state.showOtherStatistics = payload
    },
    SET_ShowDataTables(state, payload) {
      state.showDataTables = payload
    },
    SET_SaleChartDetailed(state, payload) {
      payload.forEach(function(item) {
        var temp = item.date.split('T')
        item.date = temp[0]
      })
      payload.sort(function(a, b) {
        var dateA = new Date(a)
        var dateB = new Date(b)
        if (dateA > dateB) {
          return 1
        }
        if (dateA < dateB) {
          return -1
        }
      })
      state.SaleChartDetailed = payload
    },
    SET_SaleChart(state, payload) {
      state.SaleChartDB = []
      state.SaleChartTotalRevenue = []
      for (let item in payload) {
        state.SaleChartTotalRevenue.push(payload[item].totalRevenue)
        state.SaleChartDB.push(payload[item].db)
      }
    }
  },
  actions: {
    async SET_ShowStatistics({ dispatch, commit }, payload) {
      commit('SET_ConfigStatistics', payload)
      dispatch('GET_SaleChartDetailed')
    },
    async GET_SaleChartDetailed({ commit, state }) {
      var config = state.OtherStatisticsConf
      await axios
        .get(
          `v1/statistics/article/${config.artNr}/totalrevenue?from=${config.timeFilter.fromDate}&to=${config.timeFilter.toDate}&dPoint=${config.pointIndex}`
        )
        .then(response => {
          commit('SET_SaleChartDetailed', response.data)
          commit('SET_ShowOtherStatistics', true)
        })
    },
    async GET_SaleChart({ commit }, payload) {
      await axios
        .get(
          `v1/statistics/article/${payload.artNr}/revenue?from=${payload.fromDate}&to=${payload.toDate}`
        )
        .then(response => {
          commit('SET_SaleChart', response.data)
        })
    }
  },

  getters: {
    SaleChartDetailedDates(state) {
      var Dates = []
      state.SaleChartDetailed.forEach(item => {
        Dates.push(item.date)
      })
      return Dates
    },
    SaleChartDetailedRevenue(state) {
      var Numbers = []
      state.SaleChartDetailed.forEach(item => {
        Numbers.push(item.revenue)
      })
      return Numbers
    },
    SaleChartDetailedDB(state) {
      var Numbers = []
      state.SaleChartDetailed.forEach(item => {
        Numbers.push(item.db)
      })
      return Numbers
    }
  }
}
