export default {
  data: () => ({
    loading: false,
    text: ''
  }),
  methods: {
    open(text) {
      this.loading = true;
      this.text = text;
    },
    close() {
      this.loading = false;
    }
  }
};