import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.opened,
      callback: function ($$v) {
        _vm.opened = $$v;
      },
      expression: "opened"
    }
  }, [_c(VCard, [_c(VToolbar, {
    attrs: {
      "color": "error",
      "dark": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v(" Ein Fehler ist aufgetreten ")])], 1), _c(VContainer, {
    staticClass: "pa-4"
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.failMessage) + " ")])], 1), _c(VRow, [_c(VCol, {
    staticClass: "d-flex flex-column align-end",
    attrs: {
      "cols": "12"
    }
  }, [_c(VBtn, {
    staticClass: "red--text",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c(VIcon, {
    staticClass: "pr-3"
  }, [_vm._v("mdi-cancel")]), _vm._v("Schließen")], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };