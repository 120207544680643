import axios from 'axios'
//import router from '@/router'
import formatService from '@/services/formatters/articleFormatService'

export default {
  state: {
    baseInformation: {},
    articleImages: {},
    checkboxes: {},
    characteristics: [],
    characteristicsLoader: true,
    saleprices: [],
    salepricesLoader: true,
    purchaseprices: [],
    purchasepricesLoader: true,
    transport: [],
    transportLoader: true,
    commissionedOrdered: [],
    commissionedOrderedLoader: true,
    stock: [],
    stockLoader: true,
    InventoryManagement: {},
    articleExists: true,
    openOffers: [],
    openOffersLoader: true
  },
  mutations: {
    // DateFilter
    SET_DATEFILTER_TRANSPORT(state, payload) {
      state.transport[payload.i].show = payload.boolean
      state.transport = [...state.transport]
    },
    SET_DATEFILTER_ORDERED(state, payload) {
      state.commissionedOrdered[payload.i].show = payload.boolean
      state.commissionedOrdered = [...state.commissionedOrdered]
    },

    // If new Article loaded delete old store
    SET_ZERO(state) {
      state.baseInformation = {}
      state.articleImages = {}
      state.checkboxes = {}
      state.characteristics = []
      state.characteristicsLoader = true
      state.saleprices = []
      state.salepricesLoader = true
      state.purchaseprices = []
      state.purchasepricesLoader = true
      state.transport = []
      state.transportLoader = true
      state.commissionedOrdered = []
      state.commissionedOrderedLoader = true
      state.stock = []
      state.stockLoader = true
      state.InventoryManagement = {}
    },

    // Base Information GET
    SET_BASE_INFORMATION(state, newBaseInformation) {
      state.baseInformation = newBaseInformation
      console.log('Base')
      console.log(state.baseInformation)
    },

    SET_ARTICLE_IMAGES(state, newArticleImages) {
      state.articleImages = newArticleImages
    },

    SET_CHECKBOXES(state, newCheckboxes) {
      state.checkboxes = newCheckboxes
      console.log('Checkboxes: ')
      console.log(state.checkboxes)
    },

    SET_INVENTORYMANAGEMENT(state, newInventory) {
      state.InventoryManagement = newInventory
    },

    // Data Tables SET
    SET_CHARACTERISTICS(state, newCharacteristics) {
      state.characteristics = newCharacteristics
      state.characteristicsLoader = false
      for (var i = 0, length = state.characteristics.length; i < length; i++) {
        state.characteristics[i].vpe = formatService.formatNumber(
          state.characteristics[i].vpe,
          3
        )
        state.characteristics[i].minStock = formatService.formatNumber(
          state.characteristics[i].minStock,
          3
        )
        state.characteristics[i].dep = formatService.formatNumber(
          state.characteristics[i].dep,
          3
        )
        state.characteristics[i].dbzk = formatService.formatNumber(
          state.characteristics[i].dbzk,
          3
        )
      }
      console.log('Characteristics: ')
      console.log(state.characteristics)
    },
    SET_SALEPRICES(state, newSaleprices) {
      state.saleprices = newSaleprices
      state.salepricesLoader = false
      for (var i = 0, length = state.saleprices.length; i < length; i++) {
        state.saleprices[i].validity = formatService.formatDate(
          state.saleprices[i].validity
        )
        state.saleprices[i].factor = formatService.formatNumber(
          state.saleprices[i].factor,
          5
        )
        state.saleprices[i].netprice = formatService.formatNumber(
          state.saleprices[i].netprice,
          3
        )
        state.saleprices[i].grossprice = formatService.formatNumber(
          state.saleprices[i].grossprice,
          3
        )
        state.saleprices[i].db1 = formatService.formatNumber(
          state.saleprices[i].db1,
          3
        )
        state.saleprices[i].ra = formatService.formatNumber(
          state.saleprices[i].ra,
          3
        )
      }
      console.log('SalePrices: ')
      console.log(state.saleprices)
    },
    SET_PURCHASEPRICES(state, newPurchaseprices) {
      state.purchaseprices = newPurchaseprices
      state.purchasepricesLoader = false
      for (var i = 0, length = state.purchaseprices.length; i < length; i++) {
        state.purchaseprices[i].validity = formatService.formatDate(
          state.purchaseprices[i].validity
        )
        state.purchaseprices[i].factor = formatService.formatNumber(
          state.purchaseprices[i].factor,
          5
        )
        state.purchaseprices[i].listprice = formatService.formatNumber(
          state.purchaseprices[i].listprice,
          3
        )
        state.purchaseprices[i].netprice = formatService.formatNumber(
          state.purchaseprices[i].netprice,
          3
        )
        state.purchaseprices[i].purchasePrice = formatService.formatNumber(
          state.purchaseprices[i].purchasePrice,
          3
        )
        state.purchaseprices[i].discount = formatService.formatNumber(
          state.purchaseprices[i].discount,
          3
        )
        state.purchaseprices[i].discount2 = formatService.formatNumber(
          state.purchaseprices[i].discount2,
          3
        )
      }
      console.log('PurchasePrices: ')
      console.log(state.purchaseprices)
    },
    SET_TRANSPORT(state, newTransport) {
      newTransport.sort(function(a, b) {
        var dateA = new Date(a.date),
          dateB = new Date(b.date)
        return dateB - dateA
      })
      state.transport = newTransport
      state.transportLoader = false
      for (var i = 0, length = state.transport.length; i < length; i++) {
        state.transport[i].show = true
        state.transport[i].date = formatService.formatDate(
          state.transport[i].date
        )
        state.transport[i].ldate = formatService.formatDate(
          state.transport[i].ldate
        )
        state.transport[i].netprice = formatService.formatNumber(
          state.transport[i].netprice,
          3
        )
        state.transport[i].grossprice = formatService.formatNumber(
          state.transport[i].grossprice,
          3
        )
        state.transport[i].ownContribution = formatService.formatNumber(
          state.transport[i].ownContribution,
          3
        )
      }
      console.log('Transport: ')
      console.log(state.transport)
    },
    SET_COMMISSIONED_ORDERED(state, newCommissionedOrdered) {
      state.commissionedOrdered = newCommissionedOrdered
      state.commissionedOrderedLoader = false
      for (
        var i = 0, length = state.commissionedOrdered.length;
        i < length;
        i++
      ) {
        state.commissionedOrdered[i].show = true
        state.commissionedOrdered[i].date = formatService.formatDate(
          state.commissionedOrdered[i].date
        )
        state.commissionedOrdered[i].netprice = formatService.formatNumber(
          state.commissionedOrdered[i].netprice,
          3
        )
        state.commissionedOrdered[i].grossprice = formatService.formatNumber(
          state.commissionedOrdered[i].grossprice,
          3
        )
        state.commissionedOrdered[
          i
        ].ownContribution = formatService.formatNumber(
          state.commissionedOrdered[i].ownContribution,
          3
        )
      }
    },
    SET_STOCK(state, newStock) {
      state.stock = newStock
      state.stockLoader = false
      for (var i = 0, length = state.stock.length; i < length; i++) {
        state.stock[i].inventory = formatService.formatNumber(
          state.stock[i].inventory,
          2
        )
        state.stock[i].reserved = formatService.formatNumber(
          state.stock[i].reserved,
          2
        )
        state.stock[i].ordered = formatService.formatNumber(
          state.stock[i].ordered,
          2
        )
      }
      console.log('Stock: ')
      console.log(state.stock)
    },
    SET_ARTICLE_EXISTS(state, articleExists) {
      state.articleExists = articleExists
    },
    SET_OPEN_OFFERS(state, newOpenOffers) {
      state.openOffers = newOpenOffers
      state.openOffersLoader = false
      for (var i = 0, length = state.openOffers.length; i < length; i++) {
        state.openOffers[i].show = true
        state.openOffers[i].date = formatService.formatDate(
          state.openOffers[i].date
        )
        state.openOffers[i].netprice = formatService.formatNumber(
          state.openOffers[i].netprice,
          3
        )
        state.openOffers[i].grossprice = formatService.formatNumber(
          state.openOffers[i].grossprice,
          3
        )
        state.openOffers[i].ownContribution = formatService.formatNumber(
          state.openOffers[i].ownContribution,
          3
        )
      }
    }
  },
  actions: {
    // Datumsfilter
    SET_DATEFILTER_TRANSPORT({ commit }, payload) {
      commit('SET_DATEFILTER_TRANSPORT', payload)
    },

    // Base Information GET
    async GET_BASE_INFORMATION({ commit }, articleID) {
      try {
        const response = await axios.get(`v1/articles/${articleID}`)
        commit('SET_BASE_INFORMATION', response.data)
        commit('SET_ARTICLE_EXISTS', true)
      } catch (err) {
        commit('SET_ARTICLE_EXISTS', false)
        throw err
      }
    },

    async GET_ARTICLE_IMAGES({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/image`)
        .then(response => commit('SET_ARTICLE_IMAGES', response.data))
        .catch(err => {
          throw err
        })
    },

    async GET_CHECKBOXES({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/checkboxes`)
        .then(response => commit('SET_CHECKBOXES', response.data))
        .catch(err => {
          throw err
        })
    },

    async GET_INVENTORYMANAGEMENT({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/inventory`, {
          params: { artNr: articleID }
        })
        .then(response => commit('SET_INVENTORYMANAGEMENT', response.data))
        .catch(err => {
          throw err
        })
    },

    // Data Tables GET
    async GET_CHARACTERISTICS({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/characteristics`, {
          params: { artNr: articleID }
        })
        .then(response => commit('SET_CHARACTERISTICS', response.data))
        .catch(err => {
          throw err
        })
    },
    async GET_SALEPRICES({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/prices/sales`)
        .then(response => commit('SET_SALEPRICES', response.data))
        .catch(err => {
          throw err
        })
    },
    async GET_PURCHASEPRICES({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/prices/purchases`, {
          params: { artNr: articleID }
        })
        .then(response => commit('SET_PURCHASEPRICES', response.data))
        .catch(err => {
          if (err.request.status === 404) {
            commit('SET_PURCHASEPRICES', [])
          }
          throw err
        })
    },
    async GET_TRANSPORT({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/movements`)
        .then(response => commit('SET_TRANSPORT', response.data))
        .catch(err => {
          if (err.request.status === 404) {
            commit('SET_TRANSPORT', [])
          }
          throw err
        })
    },
    async GET_COMMISSIONED_ORDERED({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/commissionedOrdered`, {
          params: { artNr: articleID }
        })
        .then(response => commit('SET_COMMISSIONED_ORDERED', response.data))
        .catch(err => {
          if (err.request.status === 404) {
            commit('SET_COMMISSIONED_ORDERED', [])
          }
          throw err
        })
    },
    async GET_STOCK({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/stock`, { params: { artNr: articleID } })
        .then(response => commit('SET_STOCK', response.data))
        .catch(err => {
          if (err.request.status === 404) {
            commit('SET_STOCK', [])
          }
          throw err
        })
    },
    async GET_OPEN_OFFERS({ commit }, articleID) {
      await axios
        .get(`v1/articles/${articleID}/openOffers`, {
          params: { artNr: articleID }
        })
        .then(response => commit('SET_OPEN_OFFERS', response.data))
        .catch(err => {
          if (err.request.status === 404) {
            commit('SET_OPEN_OFFERS', [])
            throw err
          }
        })
    }
  },
  getters: {
    getOpenOffers(state) {
      return state.openOffers
    }
  }
}
