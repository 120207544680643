export default {
  state: {
    fromDate: '',
    toDate: '',
    untilDate: ''
  },
  mutations: {
    SET_FROM_DATE(state, fromDate) {
      state.fromDate = fromDate
    },
    SET_TO_DATE(state, toDate) {
      state.toDate = toDate
    },
    SET_UNTIL_DATE(state, untilDate) {
      state.untilDate = untilDate
    }
  },
  actions: {
    setFilterFromDate({ commit }, newFromDate) {
      commit('SET_FROM_DATE', newFromDate)
    },
    setFilterToDate({ commit }, newToDate) {
      commit('SET_TO_DATE', newToDate)
    },
    setFilterUntilDate({ commit }, newToDate) {
      commit('SET_UNTIL_DATE', newToDate)
    }
  },
  getters: {
    currentTimeFilter(state) {
      return { fromDate: state.fromDate, toDate: state.toDate }
    }
  }
}
