import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.loggedIn ? _c(VFooter, {
    staticClass: "elevation-0",
    attrs: {
      "app": "",
      "inset": "",
      "color": "primary",
      "padless": ""
    }
  }, [_c(VContainer, {
    staticClass: "d-flex text-center"
  }, [_c(VContainer, {
    staticClass: "d-flex align-center justify-center pt-4 white--text"
  }, [_c('strong', [_vm._v(_vm._s(_vm.company.name) + " " + _vm._s(_vm.company.form))]), _vm._v(",   " + _vm._s(_vm.company.street) + ", " + _vm._s(_vm.company.postcode) + " " + _vm._s(_vm.company.city) + ", " + _vm._s(_vm.company.country) + ", "), _c(VIcon, {
    staticClass: "ml-4 mr-1 white--text"
  }, [_vm._v("mdi-phone")]), _vm._v(" " + _vm._s(_vm.company.tel) + " "), _c(VIcon, {
    staticClass: "ml-4 mr-1 white--text"
  }, [_vm._v("mdi-email")]), _vm._v(" " + _vm._s(_vm.company.email) + " ")], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };