import "core-js/modules/es.array.push.js";
import { DateTime } from 'luxon';
export default {
  data: () => ({
    showNotificationsMenu: false
  }),
  mounted() {
    this.loadNotificationsInterval();
  },
  methods: {
    loadNotifications() {
      this.$store.dispatch('GET_ALL_NOTIFICATIONS');
    },
    loadNotificationsInterval() {
      this.loadNotifications();
      setTimeout(this.loadNotificationsInterval, 1000 * 60);
    },
    formatSendDate(sendDate) {
      return DateTime.fromISO(sendDate).toRelative();
    },
    setNotificationToRead(notification) {
      if (notification.read == false) {
        this.$store.dispatch('CHANGE_READ_STATUS_NOTIFICATION', notification.nid);
      }
    },
    //Macht das Notificationfenster kleiner wenn Bildschirmwidth zu klein ist
    getMenuSize() {
      if (window.innerWidth > 420) {
        return 400;
      } else {
        return 340;
      }
    },
    getButtonText() {
      if (window.innerWidth > 420) {
        return 'Alle Benachrichtigungen anzeigen';
      } else {
        return 'Alle Anzeigen';
      }
    },
    getErrorMessage() {
      return 'Sie haben keine neuen Nachrichten erhalten.';
    },
    getAmountOfUnreadNotifications() {
      let unread = this.$store.state.notifications.notifications.filter(notification => notification.read == false);
      return unread.length;
    },
    openNotification(notification) {
      this.showNotificationsMenu = false;
      this.$router.push({
        name: 'Notification',
        query: {
          nid: notification.nid
        }
      });
    },
    //Öffnet Notification Übersicht
    openNotificationTable() {
      this.showNotificationsMenu = false;
      this.$router.push({
        name: 'Notification'
      });
    }
  }
};