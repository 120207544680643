import axios from 'axios'

export default {
  state: {
    status: [],
    salespersons: [],
    branchOffices: [],
    warehouses: []
  },
  mutations: {
    SET_STATUS(state, status) {
      state.status = status
    },
    SET_SALESPERSONS(state, salespersons) {
      state.salespersons = salespersons
    },
    SET_BRANCH_OFFICES(state, branchOffices) {
      state.branchOffices = branchOffices
    },
    SET_WAREHOUSES(state, warehouses) {
      state.warehouses = warehouses
    }
  },
  actions: {
    async getAllDocumentStatus({ commit }) {
      await axios
        .get(`v1/business/document/information/status`)
        .then(response => {
          console.log(response.data)
          commit('SET_STATUS', response.data)
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    async getAllSalespersons({ commit }) {
      await axios
        .get(`v1/business/document/information/salespersons`)
        .then(response => {
          commit('SET_SALESPERSONS', response.data)
        })
    },
    async getAllBranchOffices({ commit }) {
      await axios
        .get(`v1/business/document/information/branch-offices`)
        .then(response => {
          commit('SET_BRANCH_OFFICES', response.data)
        })
    },
    async getAllWarehouses({ commit }) {
      await axios
        .get(`v1/business/document/information/warehouses`)
        .then(response => {
          commit('SET_WAREHOUSES', response.data)
        })
    }
  }
}
