import dateTimeService from '@/services/cal/dateTimeService.js';
import calendarService from '@/services/cal/calendarService.js';
import SerialReservedDataDialog from './SerialReservedDataDialog.vue';
export default {
  components: {
    SerialReservedDataDialog
  },
  data: () => ({
    serialAppointmentSummary: false,
    serialPreviews: [],
    dateTimeService,
    SerialReservedDataDialog
  }),
  methods: {
    open(serialPreviews) {
      this.serialPreviews = serialPreviews;
      this.serialAppointmentSummary = true;
    },
    close() {
      this.$emit('close');
      this.serialAppointmentSummary = false;
    },
    createSerialAppointment() {
      calendarService.createSerialAppointment(this.serialPreviews).then(response => {
        console.log(response);
        this.$emit('close');
        this.serialAppointmentSummary = false;
        this.$toast.success('Terminserie wurde erstellt');
      }).catch(error => {
        if (error.status == 409) {
          this.$refs.serialResDataDialog.open(error.data, 'add');
        }
      });
    }
  }
};