import axios from 'axios'
//import { findKey } from 'core-js/core/dict'

export default {
  state: {
    /*
     articles = {
        "expired": false,
        "locked": false,
        "key": {
          "firma": 1,
          "artNr": "H03.G0.M0.00004"
        },
        "artNr2": "120365478",
        "articleDescription": "Igelball mit festen Stacheln",
        "articleDescription2": "",
        "me": "ST",
        "ustID": {}
        "chosenOrder": []
      }
    */
    articles: [],

    /*
     articleSM = 
     {
        "article": {
            "expired": false,
            "locked": false,
            "key": {
                "firma": 1,
                "artNr": "H03.G0.M0.2"
            },
            "artNr2": "H03.G0.M0.2",
            "articleDescription": "Igelball weich",
            "articleDescription2": "",
            "me": "ST",
            "chosenOrder": []
        },
        "articleSMs": [
            {
                "sm": "1000",
                "sm1": "1000",
                "sm2": null,
                "sm3": null,
                "netPrice": 10
            }
        ]
    }
    */
    articleSM: [],

    articleFormattedOverview: [],
    mengeneinheiten: [],
    USTs: []
  },

  mutations: {
    SET_USTs(state, response) {
      state.USTs = response
    },
    SET_MENGENEINHEITEN(state, response) {
      console.log('MENGENEINHEITEN')
      console.log(response)
      state.mengeneinheiten = response
    },
    SET_ADDITEM(state, article) {
      state.articles.push(article)
    },
    SET_DELETEITEM(state, article) {
      console.log(state.articleSM)
      var index = state.articles.indexOf(article)
      if (index > -1) {
        state.articleSM.splice(index, 1)
        state.articles.splice(index, 1)
      }
    },
    SET_SELECTEDARTICLESM(state, data) {
      data.response.forEach(a => {
        state.articleSM.push(a)
      })

      //Loop over data and push to articleSM
    },
    SET_ARTICLE_ORDERS(state, data) {
      state.articleOrders = data
    },
    SET_ARTICLE_FORMATTED_OVERVIEW(state, data) {
      state.articleFormattedOverview = data
    }
  },
  actions: {
    async addItem({ commit }, payload) {
      payload.ustID = {}
      commit('SET_ADDITEM', payload)
    },

    async deleteItem({ commit }, payload) {
      commit('SET_DELETEITEM', payload)
    },

    async loadSelectedArticleSM({ commit }, payload) {
      axios.post(`/v1/offer/SMs`, [payload]).then(response => {
        commit('SET_SELECTEDARTICLESM', {
          response: response.data
        })
      })
    },

    async getMengeneinheiten({ commit }) {
      await axios.get(`/v1/offer/allUnits`).then(response => {
        commit('SET_MENGENEINHEITEN', response.data)
      })
    },

    async getUSTs({ commit }) {
      await axios.get(`/v1/offer/saleTaxes`).then(response => {
        commit('SET_USTs', response.data)
      })
    }
  },

  getters: {
    //Getter
    getArticles(state) {
      return state.articles
    }
  }
}
