import "core-js/modules/es.array.push.js";
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array
    }
  },
  data: () => ({
    pictureHeaders: [{
      text: 'Dateiname',
      value: 'picName',
      align: 'start'
    }, {
      text: 'aDat',
      value: 'aDat',
      align: 'center'
    }, {
      text: 'Aktionen',
      value: 'actions',
      sortable: false,
      align: 'center',
      width: '12rem'
    }],
    search: null
  }),
  methods: {
    show(image) {
      //geklicktes Bild wird von aktueller Position entfernt
      var index = this.items.indexOf(image);
      if (index > -1) {
        this.items.splice(index, 1);
      }

      //geklicktes Bild wird als erstes Element in Liste gespeichert
      this.items.unshift(image);

      //Bilder in seperates Array extrahieren
      var displayedPics = [];
      this.items.forEach(element => {
        displayedPics.push('data:image/png;base64,' + element.picBase);
      });

      //Liste wird angezeigt
      this.$viewerApi({
        images: displayedPics
      });
    }
  }
};