import axios from 'axios'

export default {
  state: {
    //Variablen
    visibleTabs: []
  },

  mutations: {
    //Setter
    SET_VISIBLETABS(state, visibleTabs) {
      state.visibleTabs = visibleTabs
    }
  },

  actions: {
    async loadVisibleTabs({ commit }) {
      axios.get(`/v1/articles/user/permissions`).then(response => {
        commit('SET_VISIBLETABS', response.data)
      })
    }
  },

  getters: {
    //Getter
    visibleTabs(state) {
      return state.visibleTabs
    }
  }
}
