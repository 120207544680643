import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "persistent": true,
      "maxWidth": 1200,
      "width": 900
    },
    on: {
      "close": function ($event) {
        return _vm.closeDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm.address.firstname !== undefined && _vm.address.lastname !== null ? _c('span', [_vm._v(" Bilderupload für " + _vm._s(_vm.address.firstname + ' ' + _vm.address.lastname))]) : _c('span', [_vm._v(" Bilderupload für " + _vm._s(_vm.address.name))])];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm.showPictureUploadDialog ? _c('AddressPicturesUploadDialog', {
          attrs: {
            "address": _vm.address,
            "picTypes": _vm.picTypes,
            "appointmentKey": _vm.appointmentKey
          },
          on: {
            "close-dialog": function ($event) {
              return _vm.closeAddressPicturesUploadDialog();
            },
            "picture-uploaded": function ($event) {
              return _vm.loadAddressPictures(_vm.address);
            }
          }
        }) : _vm._e(), _c(VRow, {
          staticClass: "mt-2",
          attrs: {
            "no-gutters": ""
          }
        }, [_c('span', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "min-width": "60px"
          }
        }, [_vm._v("Adresse:")]), _vm._v("   "), _vm.address.address !== undefined ? [_vm.address.address.street !== undefined && _vm.address.address.street !== null ? _c('span', [_vm._v(" " + _vm._s(_vm.address.address.street) + ",  ")]) : _vm._e(), _vm.address.address.zip !== undefined && _vm.address.address.zip !== null ? _c('span', [_vm._v(" " + _vm._s(_vm.address.address.zip) + ",  ")]) : _vm._e(), _vm.address.address.city !== undefined && _vm.address.address.city !== null ? _c('span', [_vm._v(" " + _vm._s(_vm.address.address.city))]) : _vm._e()] : [_vm.address.street !== undefined && _vm.address.street !== null ? _c('span', [_vm._v(" " + _vm._s(_vm.address.street) + ",  ")]) : _vm._e(), _vm.address.plz !== undefined && _vm.address.plz !== null ? _c('span', [_vm._v(" " + _vm._s(_vm.address.plz) + ",  ")]) : _vm._e(), _vm.address.city !== undefined && _vm.address.city !== null ? _c('span', [_vm._v(" " + _vm._s(_vm.address.city))]) : _vm._e()]], 2), _c(VRow, {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('span', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "min-width": "60px"
          }
        }, [_vm._v("SVNR:")]), _vm.address.patient !== undefined && _vm.address.patient !== null ? _c('span', [_vm._v(" " + _vm._s(_vm.address.patient.svnr))]) : _vm._e(), _vm.address.patient == undefined || _vm.address.patient == null ? _c('span', [_vm._v(_vm._s('-'))]) : _vm._e()]), _c(VDivider, {
          staticClass: "mt-3"
        }), _c(VTabs, {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "align-with-title": "",
            "scrollable": ""
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v;
            },
            expression: "tab"
          }
        }, [_c(VTabsSlider, {
          attrs: {
            "color": ""
          }
        }), _c(VTab, [_vm._v(_vm._s(_vm.picTypes[0]))]), _c(VTab, [_vm._v(_vm._s(_vm.picTypes[1]))]), _c(VTab, [_vm._v(_vm._s(_vm.picTypes[2]))]), _c(VTab, [_vm._v(_vm._s(_vm.picTypes[3]))]), _c(VTab, [_vm._v(_vm._s(_vm.picTypes[4]))])], 1), _c(VTabsItems, {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v;
            },
            expression: "tab"
          }
        }, [_c('PictureTable', {
          attrs: {
            "title": _vm.picTypes[0],
            "items": _vm.images[0]
          }
        }), _c('PictureTable', {
          attrs: {
            "title": _vm.picTypes[1],
            "items": _vm.images[1]
          }
        }), _c('PictureTable', {
          attrs: {
            "title": _vm.picTypes[2],
            "items": _vm.images[2]
          }
        }), _c('PictureTable', {
          attrs: {
            "title": _vm.picTypes[3],
            "items": _vm.images[3]
          }
        }), _c('PictureTable', {
          attrs: {
            "title": _vm.picTypes[4],
            "items": _vm.images[4]
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'default'
          },
          on: {
            "click": function ($event) {
              _vm.showPictureUploadDialog = true;
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, [_vm._v(" mdi-upload ")]), _vm._v(" Hochladen ")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.opened,
      callback: function ($$v) {
        _vm.opened = $$v;
      },
      expression: "opened"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };