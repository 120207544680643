import axios from 'axios'

export default {
  state: {
    company: {
      name: '',
      street: '',
      city: '',
      country: '',
      postcode: 0,
      tel: '',
      email: '',
      logo: ''
    }
  },
  mutations: {
    SET_COMPANY_DATA(state, companyData) {
      state.company = companyData
    }
  },
  actions: {
    loadCompanyData({ commit }) {
      return axios.get('/v1/company').then(data => {
        commit('SET_COMPANY_DATA', data.data)
      })
    }
  },
  getters: {
    company(state) {
      return state.company
    }
  }
}
