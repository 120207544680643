import axios from 'axios'

export default {
  async reportAppAccess(appName) {
    await axios.get(`/v1/telemetry/app-access?appName=${appName}`)
  },
  async reportAppAccessById(appId) {
    await axios.get(`/v1/telemetry/app-access?appId=${appId}`)
  }
}
