import axios from 'axios'

export default {
  state: {
    scopes: undefined,
    types: undefined,
    locations: undefined,
    states: undefined,
    addresses: [],
    objects: [],
    resources: [],
    addressPictures: []
  },
  mutations: {
    SET_SCOPES(state, scopes) {
      state.scopes = scopes
    },
    SET_TYPES(state, types) {
      state.types = types
    },
    SET_LOCATIONS(state, locations) {
      state.locations = locations
    },
    SET_STATES(state, states) {
      state.states = states
    },
    SET_ADDRESSES(state, addresses) {
      state.addresses = addresses
    },
    SET_OBJECTS(state, objects) {
      state.objects = objects
    },
    SET_RESOURCES(state, resources) {
      state.resources = resources
    },
    SET_ADDRESS_PICTURES(state, addressPictures) {
      addressPictures.forEach(function(e) {
        if (e.aDat != null) {
          e.aDat = e.aDat.substr(0, 10)
        }
      })

      state.addressPictures = addressPictures
    }
  },
  actions: {
    async loadScopes({ commit }) {
      return axios.get('v1/calv2/information/scopes').then(data => {
        commit('SET_SCOPES', data.data)
      })
    },
    async loadTypes({ commit }) {
      return axios.get('v1/calv2/information/types').then(data => {
        commit('SET_TYPES', data.data)
      })
    },
    async loadLocations({ commit }) {
      return axios.get('v1/calv2/information/locations').then(data => {
        commit('SET_LOCATIONS', data.data)
      })
    },
    async loadStates({ commit }) {
      return axios.get('v1/calv2/information/states').then(data => {
        commit('SET_STATES', data.data)
      })
    },
    async loadAddresses({ commit }, searchText) {
      return axios
        .get('/v1/calv2/information/addressSearch', {
          params: {
            q: searchText
          }
        })
        .then(data => {
          commit('SET_ADDRESSES', data.data)
        })
    },
    async loadObjects({ commit }, appointment) {
      return axios
        .post('/v1/calv2/information/objects', appointment)
        .then(data => {
          commit('SET_OBJECTS', data.data)
        })
    },
    async loadResources({ commit }) {
      return axios.get('v1/calv2/information/resources').then(data => {
        commit('SET_RESOURCES', data.data)
      })
    },
    async loadPicturesForAddress({ commit }, { firma, lfdnr }) {
      await axios
        .post('/v1/cal/addresses/pictures/get', {
          firma: firma,
          lfdnr: lfdnr
        })
        .then(response => {
          commit('SET_ADDRESS_PICTURES', response.data)
        })
    },
    async uploadImageNames(test, { key, picNames, picType, appointmentKey }) {
      await axios.put('v1/cal/addresses/pictures', {
        key: key,
        picNames: picNames,
        picType: picType,
        appointmentKey: appointmentKey
      })
    }
  },
  getters: {
    locations(state) {
      return state.locations
    },
    scopes(state) {
      return state.scopes
    }
  }
}
