import axios from 'axios'

export default {
  state: {
    interactions: [],
    availableSubjects: []
  },
  mutations: {
    SET_INTERACTIONS(state, interactions) {
      state.interactions = interactions
    },
    SET_AVAILABLE_SUBJECTS(state, availableSubjects) {
      state.availableSubjects = availableSubjects
    }
  },
  actions: {
    loadInteractions({ commit }, { lfdnr, wf_cid }) {
      if (wf_cid == null || wf_cid == '') {
        axios.get(`/v1/crm/addresses/${lfdnr}/interactions`).then(response => {
          commit('SET_INTERACTIONS', response.data)
        })
      } else {
        axios.get(`/v1/crm/documents/${wf_cid}/interactions`).then(response => {
          commit('SET_INTERACTIONS', response.data)
        })
      }
    },

    loadAvailableSubjects({ commit }, { wf_cid }) {
      if (wf_cid == null || wf_cid == '') {
        axios.get('/v1/crm/addresses/interactions/subjects').then(response => {
          commit('SET_AVAILABLE_SUBJECTS', response.data)
        })
      } else {
        axios
          .get(`/v1/crm/documents/${wf_cid}/interactions/subjects`)
          .then(response => {
            commit('SET_AVAILABLE_SUBJECTS', response.data)
          })
      }
    },
    addInteraction({ dispatch }, { lfdnr, wf_cid, interaction }) {
      if (wf_cid == null || wf_cid == '') {
        axios
          .post(`/v1/crm/addresses/${lfdnr}/interactions`, interaction)
          .then(() => {
            dispatch('loadInteractions', { lfdnr, wf_cid })
          })
      } else {
        axios
          .post(`/v1/crm/documents/${wf_cid}/interactions`, interaction)
          .then(() => {
            dispatch('loadInteractions', { lfdnr, wf_cid })
          })
      }
    },
    editInteraction({ dispatch }, { lfdnr, wf_cid, interaction }) {
      if (wf_cid == null || wf_cid == '') {
        axios
          .put(`/v1/crm/addresses/${lfdnr}/interactions`, interaction)
          .then(() => {
            dispatch('loadInteractions', { lfdnr, wf_cid })
          })
      } else {
        axios
          .put(`/v1/crm/documents/${wf_cid}/interactions`, interaction)
          .then(() => {
            dispatch('loadInteractions', { lfdnr, wf_cid })
          })
      }
    },
    deleteInteraction({ dispatch }, { lfdnr, wf_cid, interaction }) {
      if (wf_cid == null || wf_cid == '') {
        axios
          .delete(`/v1/crm/addresses/${lfdnr}/interactions`, {
            data: interaction
          })
          .then(() => {
            dispatch('loadInteractions', { lfdnr, wf_cid })
          })
      } else {
        axios
          .delete(`/v1/crm/documents/${wf_cid}/interactions`, {
            data: interaction
          })
          .then(() => {
            dispatch('loadInteractions', { lfdnr, wf_cid })
          })
      }
    }
  },
  getters: {}
}
