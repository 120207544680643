import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VMenu, {
    attrs: {
      "close-on-content-click": false,
      "nudge-width": 200,
      "max-width": _vm.getMenuSize(),
      "max-height": "75%",
      "left": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VHover, {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              hover
            }) {
              return [_c('div', _vm._g(_vm._b({
                staticClass: "rounded-pill fluid mx-3 pa-2",
                class: {
                  hoveredIcon: hover
                }
              }, 'div', attrs, false), on), [_vm.getAmountOfUnreadNotifications() == 0 ? _c(VIcon, [_vm._v("mdi-bell")]) : _c(VBadge, {
                attrs: {
                  "color": "orange",
                  "content": _vm.getAmountOfUnreadNotifications() > 9 ? '9+' : _vm.getAmountOfUnreadNotifications(),
                  "overlap": ""
                }
              }, [_c(VIcon, [_vm._v("mdi-bell")])], 1)], 1)];
            }
          }], null, true)
        })];
      }
    }]),
    model: {
      value: _vm.showNotificationsMenu,
      callback: function ($$v) {
        _vm.showNotificationsMenu = $$v;
      },
      expression: "showNotificationsMenu"
    }
  }, [_c(VCard, {
    staticClass: "notification-container menu"
  }, [_vm.getAmountOfUnreadNotifications() != 0 ? _c(VRow, [_c(VCol, {
    staticClass: "my-2 p-2 mb-n4 d-flex justify-center font-weight-black",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.getAmountOfUnreadNotifications()) + " ungelesene Nachrichten ")]), _c(VContainer, _vm._l(this.$store.state.notifications.notifications, function (notification) {
    return _c('div', {
      key: notification.nId
    }, [notification.read == false ? _c(VHover, {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function ({
          hover
        }) {
          return [_c(VCol, {
            staticClass: "pa-0 ma-0",
            attrs: {
              "cols": "12"
            }
          }, [_c(VDivider, {
            attrs: {
              "inset": notification.inset
            }
          }), _c(VCol, {
            staticClass: "px-6 mx-lg-auto",
            class: {
              selectedNotification: hover
            },
            attrs: {
              "cols": "12"
            },
            on: {
              "click": function ($event) {
                return _vm.openNotification(notification);
              }
            }
          }, [_c(VRow, {
            staticClass: "ma-0 pa-0"
          }, [_c(VCol, {
            staticClass: "ma-0 pa-0",
            attrs: {
              "cols": "10"
            }
          }, [_c(VCol, {
            staticClass: "pa-0 ma-0 font-weight-black"
          }, [_vm._v(" " + _vm._s(notification.subject) + " ")]), _c(VCol, {
            staticClass: "pa-0 ma-0 mb-1 text--secondary font-weight-black",
            staticStyle: {
              "font-size": "12px"
            }
          }, [_vm._v(" " + _vm._s(_vm.formatSendDate(notification.sendDate)) + " | " + _vm._s(notification.fromUser) + " ")])], 1), _c(VCol, {
            staticClass: "ma-0 pa-0",
            attrs: {
              "cols": "2",
              "align": "right"
            }
          }, [_c(VHover, {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function ({
                hover
              }) {
                return [_c(VBtn, {
                  class: {
                    hoveredIcon: hover
                  },
                  attrs: {
                    "fab": "",
                    "small": "",
                    "plain": ""
                  },
                  on: {
                    "click": [function ($event) {
                      $event.stopPropagation();
                      $event.preventDefault();
                    }, function ($event) {
                      return _vm.setNotificationToRead(notification);
                    }]
                  }
                }, [_c(VIcon, [_vm._v(" mdi-email-check ")])], 1)];
              }
            }], null, true)
          })], 1)], 1)], 1)], 1)];
        }
      }], null, true)
    }) : _vm._e()], 1);
  }), 0)], 1) : _vm._e(), _vm.getAmountOfUnreadNotifications() == 0 ? _c(VRow, [_c(VCol, {
    staticClass: "mx-6 mt-4 mb-0"
  }, [_c(VIcon, {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "size": "80"
    }
  }, [_vm._v(" mdi-bell ")]), _c(VCol, {
    staticClass: "d-flex justify-content-center text-center"
  }, [_vm._v(" " + _vm._s(_vm.getErrorMessage()) + " ")])], 1)], 1) : _vm._e(), _c(VCol, {
    attrs: {
      "align": "center"
    }
  }, [_c(VBtn, {
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.openNotificationTable();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getButtonText()) + " ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };