import axios from 'axios'

export default {
  updateAppointment(appointment) {
    return axios
      .put(`/v1/calv2/appointments/update`, appointment)
      .catch(error => {
        throw error.response.data
      })
  },
  deleteAppointment(key) {
    return axios.delete(`/v1/calv2/appointments/delete`, {
      data: key
    })
  },
  async addAppointment(appointment) {
    return await axios
      .post(`/v1/calv2/appointments/create`, appointment)
      .catch(error => {
        console.log(error.response.data)
        throw error.response.data
      })
  },
  getSerialAppointmentPreview(serial) {
    return axios.post(`v1/calv2/appointments/serial/preview`, serial)
  },
  createSerialAppointment(appointments) {
    return axios
      .post(`v1/calv2/appointments/serial/create`, appointments)
      .catch(error => {
        throw error.response
      })
  },
  updateSerialAppointment(appointment) {
    return axios.put(`/v1/calv2/appointments/serial/update`, appointment)
  },
  loadAppointmentHistory(appointment) {
    return axios
      .post(`/v1/calv2/appointments/history`, appointment)
      .then(response => {
        if (response.status == 204) alert('Kein Terminverlauf vorhanden')
      })
      .catch(error => {
        throw error.response
      })
  },
  deleteSerialAppointment(serialNumber) {
    return axios.delete(`/v1/calv2/appointments/serial/delete`, {
      params: { serialNumber: serialNumber }
    })
  }
}
