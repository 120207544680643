import axios from 'axios'

export default {
  state: {
    OtherStatisticsConf: {},
    DataPerSM: null,
    DataPerAdress: null,
    DataPerAdressForSM: null,
    showSaleDataPerAdressForSM: false,
    SMcharacteristic: null
  },

  mutations: {
    //Setter
    SET_ConfigStatisticsSM(state, payload) {
      state.OtherStatisticsConf = payload
    },
    SET_DATAPERSM(state, DataPerSM) {
      state.DataPerSM = DataPerSM
    },
    SET_DATAPERADRESS(state, DataPerAdress) {
      state.DataPerAdress = DataPerAdress
    },
    SET_SHOWSALEDATAPERADRESSFORSM(state, payload) {
      state.showSaleDataPerAdressForSM = payload
    },
    SET_SMCHARACTERISTIC(state, payload) {
      state.SMcharacteristic = payload
    },
    SET_DATAPERADRESSFORSM(state, payload) {
      state.DataPerAdressForSM = payload
    }
  },

  actions: {
    async SET_ShowDataPerAdress({ dispatch, commit }, payload) {
      commit('SET_ConfigStatisticsSM', payload)
      dispatch('loadDataPerAdress')
    },
    //ladet Artikeltypen für Combobox
    async loadDataPerAdress({ commit, state }) {
      var config = state.OtherStatisticsConf
      axios
        .get(
          `v1/statistics/article/${config.artNr}/SaleDataPerAddress?from=${config.timeFilter.fromDate}&to=${config.timeFilter.toDate}&dPoint=${config.pointIndex}`
        )
        .then(response => {
          commit('SET_DATAPERADRESS', response.data)
        })
    },

    async SET_ShowDataPerSM({ dispatch, commit }, payload) {
      commit('SET_ConfigStatisticsSM', payload)
      dispatch('loadDataPerSM')
    },
    //ladet Artikeltypen für Combobox
    async loadDataPerSM({ commit, state }) {
      var config = state.OtherStatisticsConf
      axios
        .get(
          `v1/statistics/article/${config.artNr}/SaleDataSM?from=${config.timeFilter.fromDate}&to=${config.timeFilter.toDate}&dPoint=${config.pointIndex}`
        )
        .then(response => {
          commit('SET_DATAPERSM', response.data)
        })
    },

    async showSaleDataPerAdressForSM({ commit }, payload) {
      commit('SET_SHOWSALEDATAPERADRESSFORSM', payload)
    },

    async SET_ShowDataPerAdressForSM({ dispatch, commit }, payload) {
      commit('SET_SMCHARACTERISTIC', payload)
      dispatch('loadDataPerAdressForSM')
    },

    async loadDataPerAdressForSM({ commit, state }) {
      var config = state.OtherStatisticsConf
      console.log(config)
      axios
        .get(
          `v1/statistics/article/${config.artNr}/SaleDataSMPerAddress/${state.SMcharacteristic}?from=${config.timeFilter.fromDate}&to=${config.timeFilter.toDate}&dPoint=${config.pointIndex}`
        )
        .then(response => {
          commit('SET_DATAPERADRESSFORSM', response.data)
        })
    }
  },

  getters: {
    //Getter
    DataPerSM(state) {
      return state.DataPerSM
    },

    DataPerAdressForSM(state) {
      return state.DataPerAdressForSM
    }
  }
}
