import axios from 'axios'

export default {
  state: {
    masterDataApp: [],
    masterData: []
  },
  mutations: {
    SET_MASTER_DATA_APP(state, masterDataApp) {
      state.masterDataApp = masterDataApp
    },
    SET_MASTER_DATA(state, masterData) {
      state.masterData = masterData
    }
  },
  actions: {
    async loadMasterDataApp({ commit }, app) {
      return axios.get(`v1/settings/master-data/${app}`).then(data => {
        commit('SET_MASTER_DATA_APP', data.data)
      })
    },
    async loadMasterData({ commit }, type) {
      return axios
        .get(`v1/settings/master-data/category/${type}`)
        .then(data => {
          commit('SET_MASTER_DATA', data.data)
        })
    }
  }
}
