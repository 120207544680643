export default {
  state: {
    // Calculations
    minYear: -1,

    // Lists
    yearItems: [],
    shops: [],
    addressTypes: [],
    customerGroups: [],
    articleTypes: [],
    articleCategories: [],
    articleGroups: [],
    statisticGroups: [],
    statisticCode: [],
    commissionGroups: [],
    revenueGroups: [],
    expenseGroups: [],
    fibuAccounts: [],

    // Selections
    yearStart: new Date().getFullYear() - 2,
    yearEnd: new Date().getFullYear(),
    untilCurrentMonth: false,
    includeCurrentMonth: true,
    selectedRevenueTypeBool: false,
    showBothRevenueTypes: false,
    selectedRevenueTypeShort: 'net',
    selectedFilters: {
      total: true,
      shops: [],
      addressTypes: [],
      customerGroups: [],
      articleTypes: [],
      articleCategories: [],
      articleGroups: [],
      statisticGroups: [],
      statisticCode: [],
      commissionGroups: [],
      revenueGroups: [],
      expenseGroups: [],
      fibuAccounts: []
    },
    selectedCategory: undefined,
    selectedYear: new Date().getFullYear(),

    // User Feedback
    failMessage: '',
    errorDialog: false
  },
  mutations: {
    // Calculations
    SET_MIN_YEAR(state, minYear) {
      state.minYear = minYear
    },

    // Lists
    SET_YEAR_ITEMS(state, yearItems) {
      state.yearItems = yearItems
    },
    SET_SHOPS(state, shops) {
      state.shops = shops
    },
    SET_ADDRESS_TYPES(state, addressTypes) {
      state.addressTypes = addressTypes
    },
    SET_CUSTOMER_GROUPS(state, customerGroups) {
      state.customerGroups = customerGroups
    },
    SET_ARTICLE_TYPES(state, articleTypes) {
      state.articleTypes = articleTypes
    },
    SET_ARTICLE_CATEGORIES(state, articleCategories) {
      state.articleCategories = articleCategories
    },
    SET_ARTICLE_GROUPS(state, articleGroups) {
      state.articleGroups = articleGroups
    },
    SET_STATISTIC_GROUPS(state, statisticGroups) {
      state.statisticGroups = statisticGroups
    },
    SET_STATISTIC_CODE(state, statisticCode) {
      state.statisticCode = statisticCode
    },
    SET_COMMISSION_GROUPS(state, commissionGroups) {
      state.commissionGroups = commissionGroups
    },
    SET_REVENUE_GROUPS(state, revenueGroups) {
      state.revenueGroups = revenueGroups
    },
    SET_EXPENSE_GROUPS(state, expenseGroups) {
      state.expenseGroups = expenseGroups
    },
    SET_FIBU_ACCOUNTS(state, fibuAccounts) {
      state.fibuAccounts = fibuAccounts
    },

    // Selections
    SET_YEAR_START(state, yearStart) {
      state.yearStart = yearStart
    },
    SET_YEAR_END(state, yearEnd) {
      state.yearEnd = yearEnd
    },
    SET_UNTIL_CURRENT_MONTH(state, untilCurrentMonth) {
      state.untilCurrentMonth = untilCurrentMonth
    },
    SET_INCLUDE_CURRENT_MONTH(state, includeCurrentMonth) {
      state.includeCurrentMonth = includeCurrentMonth
    },
    SET_SELECTED_REVENUE_TYPE_BOOL(state, selectedRevenueTypeBool) {
      state.selectedRevenueTypeBool = selectedRevenueTypeBool
    },
    SET_SHOW_BOTH_REVENUE_TYPES(state, showBothRevenueTypes) {
      state.showBothRevenueTypes = showBothRevenueTypes
    },
    SET_SELECTED_REVENUE_TYPE_SHORT(state, selectedRevenueTypeShort) {
      state.selectedRevenueTypeShort = selectedRevenueTypeShort
    },
    SET_SELECTED_FILTERS(state, selectedFilters) {
      state.selectedFilters = selectedFilters
    },
    SET_SELECTED_CATEGORY(state, selectedCategory) {
      state.selectedCategory = selectedCategory
    },
    SET_SELECTED_YEAR(state, selectedYear) {
      state.selectedYear = selectedYear
    },

    // User Feedback
    SET_FAIL_MESSAGE(state, failMessage) {
      state.failMessage = failMessage
    },
    SET_ERROR_DIALOG(state, errorDialog) {
      state.errorDialog = errorDialog
    }
  },
  actions: {
    // Calculations
    setMinYear({ commit }, newMinYear) {
      commit('SET_MIN_YEAR', newMinYear)
    },

    // Lists
    setYearItems({ commit }, newYearItems) {
      commit('SET_YEAR_ITEMS', newYearItems)
    },
    setShops({ commit }, newShops) {
      commit('SET_SHOPS', newShops)
    },
    setAddressTypes({ commit }, newAddressTypes) {
      commit('SET_ADDRESS_TYPES', newAddressTypes)
    },
    setCustomerGroups({ commit }, newCustomerGroups) {
      commit('SET_CUSTOMER_GROUPS', newCustomerGroups)
    },
    setArticleTypes({ commit }, newArticleTypes) {
      commit('SET_ARTICLE_TYPES', newArticleTypes)
    },
    setArticleCategories({ commit }, newArticleCategories) {
      commit('SET_ARTICLE_CATEGORIES', newArticleCategories)
    },
    setArticleGroups({ commit }, newArticleGroups) {
      commit('SET_ARTICLE_GROUPS', newArticleGroups)
    },
    setStatisticGroups({ commit }, newStatisticGroups) {
      commit('SET_STATISTIC_GROUPS', newStatisticGroups)
    },
    setStatisticCode({ commit }, newStatisticCode) {
      commit('SET_STATISTIC_CODE', newStatisticCode)
    },
    setCommissionGroups({ commit }, newCommissionGroups) {
      commit('SET_COMMISSION_GROUPS', newCommissionGroups)
    },
    setRevenueGroups({ commit }, newRevenueGroups) {
      commit('SET_REVENUE_GROUPS', newRevenueGroups)
    },
    setExpenseGroups({ commit }, newExpenseGroups) {
      commit('SET_EXPENSE_GROUPS', newExpenseGroups)
    },
    setFibuAccounts({ commit }, newFibuAccounts) {
      commit('SET_FIBU_ACCOUNTS', newFibuAccounts)
    },

    // Selections
    setYearStart({ commit }, newYearStart) {
      commit('SET_YEAR_START', newYearStart)
    },
    setYearEnd({ commit }, newYearEnd) {
      commit('SET_YEAR_END', newYearEnd)
    },
    setUntilCurrentMonth({ commit }, newUntilCurrentMonth) {
      commit('SET_UNTIL_CURRENT_MONTH', newUntilCurrentMonth)
    },
    setIncludeCurrentMonth({ commit }, newIncludeCurrentMonth) {
      commit('SET_INCLUDE_CURRENT_MONTH', newIncludeCurrentMonth)
    },
    setShowBothRevenueTypes({ commit }, newShowBothRevenueTypes) {
      commit('SET_SHOW_BOTH_REVENUE_TYPES', newShowBothRevenueTypes)
    },
    setSelectedRevenueTypeBool({ commit }, newSelectedRevenueTypeBool) {
      commit('SET_SELECTED_REVENUE_TYPE_BOOL', newSelectedRevenueTypeBool)
    },
    setSelectedRevenueTypeShort({ commit }, newSelectedRevenueTypeShort) {
      commit('SET_SELECTED_REVENUE_TYPE_SHORT', newSelectedRevenueTypeShort)
    },
    setSelectedFilters({ commit }, newSelectedFilters) {
      commit('SET_SELECTED_FILTERS', newSelectedFilters)
    },
    setSelectedCategory({ commit }, newSelectedCategory) {
      commit('SET_SELECTED_CATEGORY', newSelectedCategory)
    },
    setSelectedYear({ commit }, newSelectedYear) {
      commit('SET_SELECTED_YEAR', newSelectedYear)
    },

    // User Feedback
    setFailMessage({ commit }, newFailMessage) {
      commit('SET_FAIL_MESSAGE', newFailMessage)
    },
    setErrorDialog({ commit }, newErrorDialog) {
      commit('SET_ERROR_DIALOG', newErrorDialog)
    }
  },
  getters: {
    // Calculations
    getMinYear: state => state.minYear,

    // Lists
    getYearItems: state => state.yearItems,
    getShops: state => state.shops,
    getAddressTypes: state => state.addressTypes,
    getCustomerGroups: state => state.customerGroups,
    getArticleTypes: state => state.articleTypes,
    getArticleCategories: state => state.articleCategories,
    getArticleGroups: state => state.articleGroups,
    getStatisticGroups: state => state.statisticGroups,
    getStatisticCode: state => state.statisticCode,
    getCommissionGroups: state => state.commissionGroups,
    getRevenueGroups: state => state.revenueGroups,
    getExpenseGroups: state => state.expenseGroups,
    getFibuAccounts: state => state.fibuAccounts,

    // Selections
    getYearStart: state => state.yearStart,
    getYearEnd: state => state.yearEnd,
    getUntilCurrentMonth: state => state.untilCurrentMonth,
    getIncludeCurrentMonth: state => state.includeCurrentMonth,
    getSelectedRevenueTypeBool: state => state.selectedRevenueTypeBool,
    getShowBothRevenueTypes: state => state.showBothRevenueTypes,
    getSelectedRevenueTypeShort: state => state.selectedRevenueTypeShort,
    getSelectedFilters: state => state.selectedFilters,
    getSelectedCategory: state => state.selectedCategory,
    getSelectedYear: state => state.selectedYear,

    // User Feedback
    getFailMessage: state => state.failMessage,
    getErrorDialog: state => state.errorDialog
  }
}
