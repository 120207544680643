import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VTabItem, [_c('h3', {
    staticClass: "mt-3 ml-2"
  }, [_vm._v(_vm._s(_vm.title))]), _c(VTextField, {
    staticClass: "mb-4 px-2",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c(VDataTable, {
    ref: "vuetable",
    attrs: {
      "headers": _vm.pictureHeaders,
      "items": _vm.items,
      "items-per-page": 10,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten gefunden. ")];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [item.picBase != '' ? _c('div', {
          directives: [{
            name: "viewer",
            rawName: "v-viewer"
          }],
          staticClass: "images clearfix"
        }, [_c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon'
          },
          on: {
            "click": function ($event) {
              return _vm.show(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-eye")])];
            },
            proxy: true
          }], null, true)
        })], 1) : _c('div', {
          directives: [{
            name: "viewer",
            rawName: "v-viewer"
          }],
          staticClass: "images clearfix"
        }, [_c('LBAButton', {
          attrs: {
            "buttonStyle": 'icon',
            "disabled": true
          },
          on: {
            "click": function ($event) {
              return _vm.show(item);
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-eye")])];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };