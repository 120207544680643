class Delivery {
  constructor(date, type, conditions, shippingAddressId) {
    this.date = date
    this.type = type
    this.conditions = conditions
    this.shippingAddressId = shippingAddressId
  }

  static getEmptyDelivery() {
    return new Delivery(null, null, null, null)
  }
}

class Billing {
  constructor(date, type, conditions, currency, billingAddressId, discount) {
    this.date = date
    this.type = type
    this.conditions = conditions
    this.currency = currency
    this.billingAddressId = billingAddressId
    this.doscount = discount
  }

  static getEmptyBilling() {
    return new Billing(null, null, null, null, null, null)
  }
}

class DocumentPositionPricing {
  constructor(name, netPrice, grossPrice, discount, valueAddedTax) {
    this.name = name
    this.netPrice = netPrice
    this.grossPrice = grossPrice
    this.discount = discount
    this.valueAddedTax = valueAddedTax
  }
}

class DocumentPosition {
  constructor(
    articleInformation,
    characteristic,
    quantity,
    quantityUnit,
    pricing,
    positionText
  ) {
    this.articleInformation = articleInformation
    this.characteristic = characteristic
    this.quantity = quantity
    this.quantityUnit = quantityUnit
    this.pricing = pricing
    this.positionText = positionText
  }

  static createFromArticleInformation(articleInformation) {
    return new DocumentPosition(
      articleInformation,
      null,
      null,
      articleInformation.quantityUnit,
      new DocumentPositionPricing(),
      ''
    )
  }
}

class Document {
  constructor(
    wfCid,
    customerId,
    salespersonId,
    typeId,
    status,
    branchOffice,
    warehouse,
    delivery,
    billing,
    date,
    positions
  ) {
    this.wfCid = wfCid
    this.customerId = customerId
    this.salespersonId = salespersonId
    this.typeId = typeId
    this.status = status
    this.branchOffice = branchOffice
    this.warehouse = warehouse
    this.delivery = new Delivery(
      delivery.date,
      delivery.type,
      delivery.conditions,
      delivery.shippingAddressId
    )
    this.billing = new Billing(
      billing.date,
      billing.type,
      billing.conditions,
      billing.currency,
      billing.billingAddressId,
      billing.discount
    )
    this.date = date
    this.positions = positions
  }

  static formatBusinessDocument(document) {
    return new Document(
      document.wfCid,
      document.customerId,
      document.salespersonId
    )
  }

  static getEmptyDocument() {
    return new Document(
      null,
      null,
      null,
      30,
      null,
      null,
      null,
      Billing.getEmptyBilling(),
      Delivery.getEmptyDelivery(),
      null,
      []
    )
  }
}

export {
  Document as DocumentClass,
  DocumentPosition as DocumentPositionClass,
  DocumentPositionPricing as DocumentPositionPricingClass,
  Delivery as DeliveryClass,
  Billing as BillingClass
}
