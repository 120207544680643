import axios from 'axios'

export default {
  state: {
    notifications: []
  },

  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload
    }
  },
  actions: {
    async GET_ALL_NOTIFICATIONS({ commit }) {
      await axios.get(`v1/notifications`).then(response => {
        commit('SET_NOTIFICATIONS', response.data)
      })
    },
    async CHANGE_READ_STATUS_NOTIFICATION({ dispatch }, nId) {
      axios.patch(`v1/notifications/${nId}/read`).then(() => {
        dispatch('GET_ALL_NOTIFICATIONS')
      })
    }
  }
}
